<template>
    <v-container fluid>
        <v-dialog
            v-model="labDialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <app-form
                :key="key"
                v-model="editedLab"
                :schema="getSchema('Labs')"
                :filter="labFilter"
                @cancel="labDialog = false"
                @open="labDialog = true"
                @input="onInput()"
                @delete="onInput()" />
        </v-dialog>
        <v-dialog
            v-model="labTestDialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <app-form
                :key="key"
                v-model="editedLabTest"
                :schema="getSchema('LabTests')"
                :filter="labTestFilter"
                @cancel="labTestDialog = false"
                @open="labTestDialog = true"
                @input="onInput()"
                @delete="onInput()" />
        </v-dialog>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        Lab Tests
                    </v-col>
                    <v-col>
                        <v-btn
                            v-if="canEdit('Labs')"
                            color="tertiary"
                            class="float-right"
                            dark
                            @click="labDialog = true">
                            Add Lab
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-container fluid>
                <v-row>
                    <v-col>
                        <div
                            class="align-top justify-top"
                            style="overflow-x: auto; white-space: nowrap">
                            <lab
                                v-for="lab in labs"
                                :key="lab.id"
                                :lab="lab"
                                :lab-tests="lab.items"
                                @move="onMove"
                                @add="onAdd"
                                @edit="onEdit" />
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</template>

<script>
import axios from "axios"
import bucketPageService from "@/services/bucketPageService"

export default {
    components: {
        appForm: () => import('@/components/AppForm'),
        lab: () => import('./Lab')
    },
    props: {
        filter: {
            type: Object,
            default: null
        },
        refreshKey: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            labs: [],

            editedLab: null,
            labFilter: {},
            labDialog: false,
            key: 0,

            editedLabTest: null,
            labTestFilter: {},
            labTestDialog: false
        }
    },
    watch: {
        $route() {
            this.load();
        }
    },
    async mounted() {
        await this.load();
    },
    methods: {
        async load() {
            this.labs = await bucketPageService.getLabsWithTests();
        },
        async onMove(event) {
            let labTest = event.labTest;
            labTest.labId = event.labId;

            try {
                await axios.put("/api/LabTests", labTest);
            }
            finally {
                await this.load();
            }
        },
        onAdd(labId) {
            this.editedLabTest = null;
            this.editedLab = null;
            this.key++;
            this.labTestFilter = { ...this.filter, labId };
            this.labTestDialog = true;
        },
        onEdit(labTest) {
            this.editedLabTest = labTest;
            this.labTestDialog = true;
        },
        async onInput() {
            this.editedLabTest = null;
            this.editedLab = null;
            this.labDialog = false;
            this.labTestDialog = false;
            this.key++;
            await this.load();
        }
    }
};
</script>
